import { BubbleBase } from "./BubbleBase";
import { BubbleDiv } from "./BubbleDiv";
import { executeOnSingleOrMultiple } from "tsparticles-engine";
export class Bubble extends BubbleBase {
    load(data) {
        super.load(data);
        if (!data) {
            return;
        }
        this.divs = executeOnSingleOrMultiple(data.divs, (div) => {
            const tmp = new BubbleDiv();
            tmp.load(div);
            return tmp;
        });
    }
}
